.btn-scrollTop{
    position: fixed;
    bottom: 90px;
    font-size: 3rem;
    z-index: 2000;
    cursor: pointer;
    color: green;
    right: 2%;
    background: none;
    border-radius: 50px;
    padding: 0px;
    border: none;
    opacity: 0.7;
}